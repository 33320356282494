import {
  HttpEvent,
  HttpHandlerFn,
  HttpHeaders,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';

import { Observable } from 'rxjs';

import { StorageService } from '@shared/services/shared-services/storage.service';

import { environment } from '../../../environments/environment';

export const HeaderInterceptor: HttpInterceptorFn = (
  req: HttpRequest<any>,
  next: HttpHandlerFn,
): Observable<HttpEvent<any>> => {
  const storageService = inject(StorageService);
  const subscriptionKey = environment.subscriptionKey;

  let headers = req.headers;

  if (
    req.url.includes(environment.endpointViaCep) ||
    req.url.includes(environment.endpontReceita)
  ) {
    headers = setHeaders(req.url, req.headers);
  } else {
    headers = headers
      .set('Ocp-Apim-Subscription-Key', subscriptionKey)
      .set('Authorization', `Bearer ${storageService.getItem('accessToken')}`)
      .set('Accept', '*/*');
  }
  const clonedReq = req.clone({ headers });

  return next(clonedReq);
};

const setHeaders = (url: string, headers: HttpHeaders): HttpHeaders => {
  if (url.includes(environment.endpointViaCep)) {
    headers = headers.set('Content-Type', 'application/json');
  }

  return headers;
};
