<section class="tw-p-6">
  <div class="tw-flex tw-justify-center tw-mb-8">
    <div
      class="tw-bg-orange-b2 tw-rounded-full tw-px-3 tw-py-2 tw-flex tw-items-center tw-justify-center"
    >
      <span class="biz-sherif tw-text-3xl"></span>
    </div>
  </div>

  <p
    class="tw-text-gray-biz tw-font-semibold tw-text-2xl tw-mb-4 tw-text-center"
  >
    Sessão Encerrada
  </p>

  <div class="tw-flex tw-flex-col tw-items-center">
    <span class="tw-text-gray-biz"
      >Detectamos outro acesso e desconectamos você.
    </span>
    <span class="tw-text-gray-biz"
      >Não foi você? Atualize sua senha ou chame o suporte.</span
    >
  </div>

  <button
    mat-dialog-close
    mat-button
    class="tw-mt-8 tw-text-lg tw-h-14 tw-w-full tw-bg-primary tw-font-semibold tw-text-white"
  >
    Realizar login
  </button>
</section>
