import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FunctionalityAndPermissionModel } from '@shared/models/manager/functionality.model';

import { ParamValue } from '@shared/models/param-value.model';
import { QueryOptions } from '@shared/models/query-options.model';
import { Observable, map } from 'rxjs';

import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FunctionalityService {
  private urlManager = environment.endpointManager;

  constructor(private http: HttpClient) {}

  public getFunctionalityAndPermission(
    profileId: string,
    param?: Array<ParamValue>,
  ): Observable<Array<FunctionalityAndPermissionModel>> {
    const params = new QueryOptions(param).getUrlSearchParams();

    const httpOptions = {
      params,
    };
    return this.http
      .get<Array<FunctionalityAndPermissionModel>>(
        `${this.urlManager}/functionality/profile/${profileId}`,
        httpOptions,
      )
      .pipe(map((response) => response));
  }

  public getFunctionalityAndPermissionByProfileId(
    profileId: string,
    param?: Array<ParamValue>,
  ): Observable<Array<FunctionalityAndPermissionModel>> {
    const params = new QueryOptions(param).getUrlSearchParams();

    const httpOptions = {
      params,
    };
    return this.http
      .get<Array<FunctionalityAndPermissionModel>>(
        `${this.urlManager}/functionality/profile/${profileId}`,
        httpOptions,
      )
      .pipe(map((response) => response));
  }

  public getFunctionalityAndPermissionForEditedProfile(
    companyId: string,
    profileId: string,
  ): Observable<Array<FunctionalityAndPermissionModel>> {
    return this.http
      .get<Array<FunctionalityAndPermissionModel>>(
        `${this.urlManager}/functionality/company/${companyId}/profile/${profileId}`,
      )
      .pipe(map((response) => response));
  }
}
