import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MatDialogState,
} from '@angular/material/dialog';

import { SessionService } from '@shared/services/shared-services/session.service';

import { SessionClosedModalComponent } from './components/session-closed-modal/session-closed-modal.component';

@Injectable({ providedIn: 'root' })
export class UnauthorizedService {
  constructor(
    private sessionService: SessionService,
    private dialog: MatDialog,
  ) {}

  private loadingDialog!: MatDialogRef<SessionClosedModalComponent>;

  public handleUnauthorizedAccess(httpErrorResponse: HttpErrorResponse): void {
    if (httpErrorResponse.status === HttpStatusCode.Unauthorized) {
      if (httpErrorResponse.error) {
        this.handleConcurrentAccess();
      } else {
        this.sessionService.logoff();
      }
    }
  }

  private handleConcurrentAccess(): void {
    if (this.loadingDialog?.getState() === MatDialogState.OPEN) {
      return;
    }

    this.dialog
      .open(SessionClosedModalComponent, {
        disableClose: true,
      })
      .afterClosed()
      .subscribe(() => {
        this.sessionService.logoff();
      });
  }
}
