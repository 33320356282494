import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { SessionService } from '@shared/services/shared-services/session.service';

import { ColorBlockModule } from 'ngx-color/block';
import { NgxPaginationModule } from 'ngx-pagination';
import { retry, Subscription } from 'rxjs';
import { BreadcrumbModule } from 'xng-breadcrumb';

import { environment } from '@environments/environment';
import { GeneralHttpErrorMixin } from '@shared/mixins/general-http-error-mixin';
import { MixinHandler } from '@shared/mixins/mixin-handler';
import { AccessAllIWrapper } from '@shared/models/manager.model';
import { LicenseModel } from '@shared/models/manager/license.model';
import { ParamValue } from '@shared/models/param-value.model';
import { CompanyService } from '@shared/services/frame-services/company.service';
import { LincenseService } from '@shared/services/frame-services/license.service';
import { ManagerService } from '@shared/services/frame-services/manager.service';
import { UserService } from '@shared/services/frame-services/user.service';
import { SettingsTokenService } from '@shared/services/settings/settings-token.service';
import { PermissionService } from '@shared/services/shared-services/permission.service';
import { StorageService } from '@shared/services/shared-services/storage.service';
import { ThemeService } from '@shared/services/shared-services/theme.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [
    RouterModule,
    MatIconModule,
    MatDialogModule,
    NgxPaginationModule,
    ColorBlockModule,
    BreadcrumbModule,
  ],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  public searchParams = new Array<ParamValue>();
  private invertGray = false;
  private invertContrast = false;
  private body!: HTMLElement;
  private subscription: Subscription = new Subscription();

  private handleHttpError!: (
    customMessageError: string,
    httpError: HttpErrorResponse,
    reqMethod: 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'GET',
  ) => void;

  constructor(
    private themeService: ThemeService,
    private managerService: ManagerService,
    private lincenseService: LincenseService,
    private companyService: CompanyService,
    private storageService: StorageService,
    private sessionService: SessionService,
    private permissionService: PermissionService,
    private userService: UserService,
    private settingsTokenService: SettingsTokenService,
    private location: Location,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.settingsTokenService.getTokenUrlSetStorage();

    MixinHandler.applyMixins(AppComponent, [GeneralHttpErrorMixin]);

    this.themeService.changeTheme();

    this.getAccessAllCompany();
  }

  ngAfterViewInit(): void {
    this.body = document.body;
    this.grayscale(this.storageService.getItem('grayscale'));
    this.contrast(this.storageService.getItem('contrast'));
  }

  grayscale(invertGray: boolean): void {
    this.invertGray = invertGray;
    this.body.classList.toggle('grayscale', this.invertGray);
  }

  contrast(contrast: boolean): void {
    this.invertContrast = contrast;
    this.body.classList.toggle('invert', this.invertContrast);
  }

  private getAccessAllCompany(): void {
    this.managerService
      .getAccessAll(this.storageService.getItem('userId'))
      .pipe(retry({ count: 2, delay: 3000 }))
      .subscribe({
        next: (next) => this.handleAccessLastResponse(next),
        error: (err) =>
          this.handleHttpError('Erro ao carregar companhia!', err, 'GET'),
      });
  }

  public handleAccessLastResponse(resp: Array<AccessAllIWrapper>) {
    this.managerService.setAccessAllSubject(resp);

    let access: AccessAllIWrapper | undefined = resp.find(
      (access) => access.lastAccess === true,
    );

    if (!resp.find((access) => access.lastAccess === true)) {
      access = resp[0];
    }

    this.managerService.setCompanyLoggedSubject(access!);

    this.storageService.setItem('companyId', access?.companyId);
    this.storageService.setItem('jobTitle', access?.profileName);
    this.storageService.setItem('profileId', access?.profileId);
    this.storageService.setItem('user', access?.userName);
    this.userService.setImgUserSubject(access?.userImage);

    this.permissionService.permission();

    this.handleCompanyResponse(access);

    this.getLicense(access!.companyId);
  }

  public handleCompanyResponse(resp: any): void {
    const favIcon: HTMLLinkElement | null = document.querySelector('#app-icon');

    resp.isCustomCompany =
      this.is99Company(resp.companyDocument) ||
      this.isBizCompany(resp.companyDocument);
    this.companyService.setCompanyResponseSubject(resp);

    this.storageService.setItem('masterCompanyId', resp.masterCompanyId);
    this.storageService.setItem('typeC', resp.companyTypeId);
    this.storageService.setItem('isCustomCompany', resp.isCustomCompany);

    this.themeService.setCustomTextColor(
      this.is99Company(resp.companyDocument),
    );

    if (this.storageService.getItem('company') === 'elo') {
      this.themeService.changeTheme('#003933', '#2A353C');
      if (favIcon) {
        favIcon.href = environment.production
          ? 'https://cdn-aiofiles-prd.biz.com.br/site/clients/bb/logoEloCir.png'
          : 'https://cdn-aiofiles.biz.com.br/site/clients/bb/logoEloCir.png';
      }
      return;
    }

    if (this.storageService.getItem('company') === 'biz') {
      if (resp?.companyIcon && favIcon) {
        favIcon.href = resp.companyIcon;
      }
    }

    if (this.storageService.getItem('company') === 'raio' && favIcon) {
      favIcon.href = environment.production
        ? 'https://cdn-aiofiles-prd.biz.com.br/site/imgs/raio-icon.png'
        : 'https://cdn-aiofiles.biz.com.br/site/imgs/raio-icon.png';
    } else if (this.storageService.getItem('company') === 'elo' && favIcon) {
      favIcon!.href = environment.production
        ? 'https://cdn-aiofiles-prd.biz.com.br/site/clients/bb/logoEloCir.png'
        : 'https://cdn-aiofiles.biz.com.br/site/clients/bb/logoEloCir.png';
    }

    this.themeService.changeTheme(
      resp?.companyPrimaryColor,
      this.getSecondaryColor(resp.companyDocument),
    );
  }

  private getLicense(companyId: string): void {
    this.subscription.add(
      this.lincenseService.getLicenses(companyId).subscribe({
        next: (next) => this.getProductsAndLicensesSuccess(next),
        error: (err) =>
          this.handleHttpError(
            'Erro na consulta de produtos e licenças',
            err,
            'GET',
          ),
      }),
    );
  }

  private getProductsAndLicensesSuccess(getLicenseSuccess: LicenseModel[]) {
    this.lincenseService.setLicensesSubject(getLicenseSuccess);
  }

  getSecondaryColor(document: string): string {
    if (this.is99Company(document)) {
      return '#000000';
    }
    return '#2A353C';
  }

  is99Company(document: string): boolean {
    return document === '18033552000161' || document === '24313102000125';
  }

  isBizCompany(document: string): boolean {
    return document === '07155434000100';
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
