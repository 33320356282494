import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Subscription, Observable, BehaviorSubject } from 'rxjs';
import { toLower } from 'lodash';

import { PermissionModel } from '../../models/manager.model';
import { MixinHandler } from '../../mixins/mixin-handler';
import { SearchParamsMixin } from '../../mixins/search-params.mixin';
import { ParamValue } from '../../models/param-value.model';
import { hasPermission } from '../../models/permission.model';

import { SnackbarService } from '@shared/services/shared-services/snackbar.service';

import { roles } from '../../constants/roles.constants';
import { Wrapper } from '../../models/pagination-wrapper';
import { GeneralHttpErrorMixin } from '../../mixins/general-http-error-mixin';

import { FunctionalityService } from '../frame-services/functionality.service';
import { FunctionalityAndPermissionModel } from '@shared/models/manager/functionality.model';
import { StorageService } from './storage.service';
import { ManagerService } from '../frame-services/manager.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  public features!: any;
  public subscription: Subscription = new Subscription();
  public searchParams = new Array<ParamValue>();
  public permissions!: Wrapper<PermissionModel>;
  public searchPermission = new Array<any>();
  public permissionAvailable!: Observable<hasPermission>;
  public setParamValues!: (key: string, value: string) => false;
  public roles: typeof roles = roles;
  public permissionSubject = new BehaviorSubject<boolean>(false);

  private newPermission: any = [];

  private handleHttpError!: (
    customMessageError: string,
    httpError: HttpErrorResponse,
    reqMethod: 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'GET',
  ) => void;

  constructor(
    private functionalityService: FunctionalityService,
    private storageService: StorageService,
    private snackbarService: SnackbarService,
    private managerService: ManagerService,
  ) {}

  // Permission management
  public permission(): void {
    MixinHandler.applyMixins(PermissionService, [
      SearchParamsMixin,
      GeneralHttpErrorMixin,
    ]);

    this.newPermission = [];

    this.setParamValues('visible', 'true');
    this.subscription.add(
      this.functionalityService
        .getFunctionalityAndPermissionByProfileId(
          this.storageService.getItem('profileId'),
          this.searchParams,
        )
        .subscribe({
          next: (next) => this.getFunctionlityResponse(next),
          error: (err) =>
            this.handleHttpError(
              'Erro ao consultar funcionalidades!',
              err,
              'GET',
            ),
        }),
    );
  }

  // gets a feature get response and the related product name
  private getFunctionlityResponse(
    functionalitys: Array<FunctionalityAndPermissionModel>,
  ) {
    this.permissionGrouper(functionalitys);
    this.permissionSubject.next(true);
  }

  // Responsible for grouping the functionality and permission
  public permissionGrouper(functionalityChilds: any) {
    functionalityChilds.map((item: any) => {
      if (item) {
        this.newPermission.push(item);
      }

      if (item.functionalityChilds.length > 0) {
        this.permissionGrouper(item.functionalityChilds);
      }
    });
  }

  // responsible for getting the permission and the related products
  public hasPermission(featureName: string): any {
    this.roles = roles;

    const selectPermisson = this.newPermission.find(
      (permission: any) => toLower(permission.code) === toLower(featureName),
    );

    if (
      !this.managerService.companyLogged().companyRequiresOrderApproval &&
      selectPermisson?.code === 'BENEFICIOS_gestao_aprovacao_de_recarga'
    ) {
      return roles;
    }

    if (
      this.storageService.getItem('isCustomCompany') === 'false' &&
      selectPermisson?.code === 'Beneficios_recarga_de_premios'
    ) {
      return roles;
    }

    if (selectPermisson) {
      this.roles = selectPermisson.permission;
    }

    return this.roles;
  }

  /////////////////////////////////////////////// SUBJECT ////////////////////////////////////////////////////

  getPermissionAvailableSubject(): Observable<boolean> {
    return this.permissionSubject.asObservable();
  }
}
