import { registerLocaleData } from '@angular/common';
import {
  provideHttpClient,
  withFetch,
  withInterceptors,
  withJsonpSupport,
} from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { ApplicationConfig, LOCALE_ID } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MAT_RIPPLE_GLOBAL_OPTIONS,
  provideNativeDateAdapter,
  RippleGlobalOptions,
} from '@angular/material/core';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter, withComponentInputBinding } from '@angular/router';

import player from 'lottie-web';
import { provideLottieOptions } from 'ngx-lottie';
import { provideEnvironmentNgxMask } from 'ngx-mask';

import { HeaderInterceptor } from '@shared/interceptors/header.interceptor';
import { ErrorInterceptor } from '@shared/interceptors/http-error-interceptor';
import { CustomDateAdapter } from '@shared/locale/custom-date-adapter';
import { SessionService } from '@shared/services/shared-services/session.service';
import { StorageService } from '@shared/services/shared-services/storage.service';

import { routes } from './app.routes';

registerLocaleData(localePt);

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM-YYYY',
  },
};

const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withComponentInputBinding()),
    provideHttpClient(
      withInterceptors([HeaderInterceptor, ErrorInterceptor]),
      withFetch(),
      withJsonpSupport(),
    ),
    provideClientHydration(),
    provideAnimationsAsync(),
    provideLottieOptions({
      player: () => player,
    }),
    provideEnvironmentNgxMask(),
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    provideNativeDateAdapter(),
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
    StorageService,
    SessionService,
  ],
};
