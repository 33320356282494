import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import moment from 'moment';

import { StorageService } from '../shared-services/storage.service';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class SettingsTokenService {
  constructor(
    private router: Router,
    private storageService: StorageService,
    private location: Location,
  ) {}

  public getTokenUrlSetStorage(): void {
    this.errorToken();

    const url = this.location.path(true);

    const fragmentIndex = url.indexOf('#');

    if (fragmentIndex !== -1) {
      const fragment = url.substring(fragmentIndex + 1);
      const params = new URLSearchParams(fragment);
      const token = params.get('id_token') || params.get('access_token');

      this.storageService.setItem('accessToken', token);

      const decodedToken = this.storageService.getjwt(token!);

      this.storageService.setItem('userId', decodedToken.oid);
      this.storageService.setItem('isAdmin', decodedToken.extension_Role);

      const expireDate = decodedToken?.exp * 1000;
      this.storageService.setItem(
        'tokenExpires',
        moment(expireDate).format().toLocaleString(),
      );

      this.router.navigate(['allinone/home']);

      if (this.storageService.getItem('accessToken')) {
        environment.local
          ? this.setConfigLocal()
          : this.getAcr(this.storageService.getItem('accessToken'));
      }
    }
  }

  private setConfigLocal(): void {
    this.storageService.setItem('company', 'biz');
    document.title = 'Biz';
  }

  private getAcr(jwt: string): void {
    const acr_token: string = this.storageService.getjwt(jwt).acr.split('_')[2];

    if (acr_token === 'signin') {
      this.storageService.setItem('company', 'biz');
      document.title = 'Biz';
    } else if (acr_token === 'elo') {
      this.storageService.setItem('company', 'elo');
      document.title = 'Elo MultiBenefícios';
    } else {
      this.storageService.setItem('company', acr_token);
      document.title = 'Raiô Benefícios';
    }
  }

  private errorToken(): void {
    if (window.location.href.includes('error=server')) {
      this.storageService.removeAll();
      alert('Erro de servidor');
    }

    if (window.location.href.includes('error')) {
      this.storageService.removeAll();
    }
  }
}
