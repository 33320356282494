import * as i0 from '@angular/core';
import { EventEmitter, Component, Input, Output, forwardRef, ChangeDetectionStrategy, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i2 from 'ngx-color';
import { ColorWrap, getContrastingColor, isValidHex, CheckboardModule, SwatchModule, EditableInputModule } from 'ngx-color';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
function BlockSwatchesComponent_color_swatch_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "color-swatch", 3);
    i0.ɵɵlistener("onClick", function BlockSwatchesComponent_color_swatch_1_Template_color_swatch_onClick_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.handleClick($event));
    })("onHover", function BlockSwatchesComponent_color_swatch_1_Template_color_swatch_onHover_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onSwatchHover.emit($event));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const c_r3 = ctx.$implicit;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵstyleMap(ctx_r1.swatchStyle);
    i0.ɵɵproperty("color", c_r3)("focusStyle", ctx_r1.focusStyle(c_r3));
  }
}
const _c0 = (a0, a1) => ({
  input: a0,
  wrap: a1
});
function BlockComponent_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "div", 7);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵstyleProp("border-color", "transparent transparent " + ctx_r0.hex + " transparent");
  }
}
function BlockComponent_color_checkboard_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "color-checkboard", 8);
  }
}
class BlockSwatchesComponent {
  colors;
  onClick = new EventEmitter();
  onSwatchHover = new EventEmitter();
  swatchStyle = {
    width: '22px',
    height: '22px',
    float: 'left',
    marginRight: '10px',
    marginBottom: '10px',
    borderRadius: '4px'
  };
  handleClick({
    hex,
    $event
  }) {
    this.onClick.emit({
      hex,
      $event
    });
  }
  focusStyle(c) {
    return {
      boxShadow: `${c} 0 0 4px`
    };
  }
  static ɵfac = function BlockSwatchesComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || BlockSwatchesComponent)();
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: BlockSwatchesComponent,
    selectors: [["color-block-swatches"]],
    inputs: {
      colors: "colors"
    },
    outputs: {
      onClick: "onClick",
      onSwatchHover: "onSwatchHover"
    },
    decls: 3,
    vars: 1,
    consts: [[1, "block-swatches"], [3, "color", "style", "focusStyle", "onClick", "onHover", 4, "ngFor", "ngForOf"], [1, "clear"], [3, "onClick", "onHover", "color", "focusStyle"]],
    template: function BlockSwatchesComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵtemplate(1, BlockSwatchesComponent_color_swatch_1_Template, 1, 4, "color-swatch", 1);
        i0.ɵɵelement(2, "div", 2);
        i0.ɵɵelementEnd();
      }
      if (rf & 2) {
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngForOf", ctx.colors);
      }
    },
    dependencies: [i1.NgForOf, i2.SwatchComponent],
    styles: [".block-swatches[_ngcontent-%COMP%]{margin-right:-10px}.clear[_ngcontent-%COMP%]{clear:both}"]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BlockSwatchesComponent, [{
    type: Component,
    args: [{
      selector: 'color-block-swatches',
      template: `
    <div class="block-swatches">
      <color-swatch
        *ngFor="let c of colors"
        [color]="c"
        [style]="swatchStyle"
        [focusStyle]="focusStyle(c)"
        (onClick)="handleClick($event)"
        (onHover)="onSwatchHover.emit($event)"
      ></color-swatch>
      <div class="clear"></div>
    </div>
  `,
      styles: [".block-swatches{margin-right:-10px}.clear{clear:both}\n"]
    }]
  }], null, {
    colors: [{
      type: Input
    }],
    onClick: [{
      type: Output
    }],
    onSwatchHover: [{
      type: Output
    }]
  });
})();
class BlockComponent extends ColorWrap {
  /** Pixel value for picker width */
  width = 170;
  /** Color squares to display */
  colors = ['#D9E3F0', '#F47373', '#697689', '#37D67A', '#2CCCE4', '#555555', '#dce775', '#ff8a65', '#ba68c8'];
  triangle = 'top';
  input = {
    width: '100%',
    fontSize: '12px',
    color: '#666',
    border: '0px',
    outline: 'none',
    height: '22px',
    boxShadow: 'inset 0 0 0 1px #ddd',
    borderRadius: '4px',
    padding: '0 7px',
    boxSizing: 'border-box'
  };
  wrap = {
    position: 'relative',
    width: '100%'
  };
  disableAlpha = true;
  constructor() {
    super();
  }
  handleValueChange({
    data,
    $event
  }) {
    this.handleBlockChange({
      hex: data,
      $event
    });
  }
  getContrastingColor(hex) {
    return getContrastingColor(hex);
  }
  handleBlockChange({
    hex,
    $event
  }) {
    if (isValidHex(hex)) {
      // this.hex = hex;
      this.handleChange({
        hex,
        source: 'hex'
      }, $event);
    }
  }
  static ɵfac = function BlockComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || BlockComponent)();
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: BlockComponent,
    selectors: [["color-block"]],
    inputs: {
      width: "width",
      colors: "colors",
      triangle: "triangle"
    },
    features: [i0.ɵɵProvidersFeature([{
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BlockComponent),
      multi: true
    }, {
      provide: ColorWrap,
      useExisting: forwardRef(() => BlockComponent)
    }]), i0.ɵɵInheritDefinitionFeature],
    decls: 9,
    vars: 17,
    consts: [["class", "block-triangle", 3, "border-color", 4, "ngIf"], [1, "block-head"], ["borderRadius", "6px 6px 0 0", 4, "ngIf"], [1, "block-label"], [1, "block-body"], [3, "onClick", "onSwatchHover", "colors"], [3, "onChange", "value"], [1, "block-triangle"], ["borderRadius", "6px 6px 0 0"]],
    template: function BlockComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelementStart(0, "div");
        i0.ɵɵtemplate(1, BlockComponent_div_1_Template, 1, 2, "div", 0);
        i0.ɵɵelementStart(2, "div", 1);
        i0.ɵɵtemplate(3, BlockComponent_color_checkboard_3_Template, 1, 0, "color-checkboard", 2);
        i0.ɵɵelementStart(4, "div", 3);
        i0.ɵɵtext(5);
        i0.ɵɵelementEnd()();
        i0.ɵɵelementStart(6, "div", 4)(7, "color-block-swatches", 5);
        i0.ɵɵlistener("onClick", function BlockComponent_Template_color_block_swatches_onClick_7_listener($event) {
          return ctx.handleBlockChange($event);
        })("onSwatchHover", function BlockComponent_Template_color_block_swatches_onSwatchHover_7_listener($event) {
          return ctx.onSwatchHover.emit($event);
        });
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(8, "color-editable-input", 6);
        i0.ɵɵlistener("onChange", function BlockComponent_Template_color_editable_input_onChange_8_listener($event) {
          return ctx.handleValueChange($event);
        });
        i0.ɵɵelementEnd()()();
      }
      if (rf & 2) {
        i0.ɵɵclassMapInterpolate1("block-card block-picker ", ctx.className, "");
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.triangle !== "hide");
        i0.ɵɵadvance();
        i0.ɵɵstyleProp("background", ctx.hex);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.hex === "transparent");
        i0.ɵɵadvance();
        i0.ɵɵstyleProp("color", ctx.getContrastingColor(ctx.hex));
        i0.ɵɵadvance();
        i0.ɵɵtextInterpolate1(" ", ctx.hex, " ");
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("colors", ctx.colors);
        i0.ɵɵadvance();
        i0.ɵɵstyleMap(i0.ɵɵpureFunction2(14, _c0, ctx.input, ctx.wrap));
        i0.ɵɵproperty("value", ctx.hex);
      }
    },
    dependencies: () => [i1.NgIf, i2.CheckboardComponent, i2.EditableInputComponent, BlockSwatchesComponent],
    styles: [".block-card[_ngcontent-%COMP%]{background:#fff;border-radius:6px;box-shadow:0 1px #0000001a;position:relative}.block-head[_ngcontent-%COMP%]{align-items:center;border-radius:6px 6px 0 0;display:flex;height:110px;justify-content:center;position:relative}.block-body[_ngcontent-%COMP%]{padding:10px}.block-label[_ngcontent-%COMP%]{font-size:18px;position:relative}.block-triangle[_ngcontent-%COMP%]{border-style:solid;border-width:0 10px 10px 10px;height:0;left:50%;margin-left:-10px;position:absolute;top:-10px;width:0}"],
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BlockComponent, [{
    type: Component,
    args: [{
      selector: 'color-block',
      template: `
  <div class="block-card block-picker {{ className }}">
    <div class="block-triangle" *ngIf="triangle !== 'hide'"
      [style.border-color]="'transparent transparent ' + this.hex + ' transparent'"
    ></div>

    <div class="block-head" [style.background]="hex">
      <color-checkboard *ngIf="hex === 'transparent'"
        borderRadius="6px 6px 0 0"
      ></color-checkboard>
      <div class="block-label" [style.color]="getContrastingColor(hex)">
        {{ hex }}
      </div>
    </div>

    <div class="block-body">
      <color-block-swatches [colors]="colors"
        (onClick)="handleBlockChange($event)"
        (onSwatchHover)="onSwatchHover.emit($event)"
      ></color-block-swatches>
      <color-editable-input [value]="hex"
        (onChange)="handleValueChange($event)"
        [style]="{input: input, wrap: wrap}"
      ></color-editable-input>
    </div>
  </div>
  `,
      preserveWhitespaces: false,
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => BlockComponent),
        multi: true
      }, {
        provide: ColorWrap,
        useExisting: forwardRef(() => BlockComponent)
      }],
      styles: [".block-card{background:#fff;border-radius:6px;box-shadow:0 1px #0000001a;position:relative}.block-head{align-items:center;border-radius:6px 6px 0 0;display:flex;height:110px;justify-content:center;position:relative}.block-body{padding:10px}.block-label{font-size:18px;position:relative}.block-triangle{border-style:solid;border-width:0 10px 10px 10px;height:0;left:50%;margin-left:-10px;position:absolute;top:-10px;width:0}\n"]
    }]
  }], function () {
    return [];
  }, {
    width: [{
      type: Input
    }],
    colors: [{
      type: Input
    }],
    triangle: [{
      type: Input
    }]
  });
})();
class ColorBlockModule {
  static ɵfac = function ColorBlockModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ColorBlockModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: ColorBlockModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule, CheckboardModule, SwatchModule, EditableInputModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ColorBlockModule, [{
    type: NgModule,
    args: [{
      declarations: [BlockComponent, BlockSwatchesComponent],
      exports: [BlockComponent, BlockSwatchesComponent],
      imports: [CommonModule, CheckboardModule, SwatchModule, EditableInputModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { BlockComponent, BlockSwatchesComponent, ColorBlockModule };
