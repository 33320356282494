import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, Router } from '@angular/router';

import { environment } from '@environments/environment';
import { DefaultConstants } from '@shared/constants/default.constants';
import { StorageService } from '@shared/services/shared-services/storage.service';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthRedirectGuard implements CanLoad {
  constructor(private storageService: StorageService) {}

  canLoad(
    route: Route,
    segments: UrlSegment[],
  ): boolean | Observable<boolean> | Promise<boolean> {
    if (!this.storageService.getItem('accessToken')) {
      const currentUrls = window.location.href;
      let redirectLogin = '';

      if (currentUrls.includes('biz')) {
        redirectLogin = environment.production
          ? DefaultConstants.azureB2CAuthUrlPROD
          : DefaultConstants.azureB2CAuthUrlHML;
      } else if (currentUrls.includes('raio')) {
        redirectLogin = environment.production
          ? DefaultConstants.azureB2CAuthUrlPRODRaio
          : DefaultConstants.azureB2CAuthUrlHMLRaio;
      } else if (currentUrls.includes('elo')) {
        redirectLogin = environment.production
          ? DefaultConstants.azureB2CAuthUrlBancobbPROD
          : DefaultConstants.azureB2CAuthUrlBancobbHML;
      }

      if (redirectLogin) {
        window.location.href = redirectLogin;
        return false;
      }
    }

    return true;
  }
}
